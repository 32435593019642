import "./App.css";
import React from "react";

function App() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={"/logo.png"} alt={"logo"} />
      <h1>HER AI</h1>
    </div>
  );
}

export default App;
