import "./App.css";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import DeleteAccount from "./DeleteAccount";
import React, { useState } from "react";

// const appleResponse = (response) => {
//   console.log("sign in with apple response ++++++++++++ ");
//   console.log(response);
//   console.log("sign in with apple response ------------ ");
// };

function Delete() {
  const [AfterLogin, setAfterLogin] = useState(false);
  const [ClientInfo, setClientInfo] = useState([]);
  const [checked, setChecked] = useState(false);
  const [onPrecess, setOnProcess] = useState(false);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
        }}
      >
        {AfterLogin ? (
          <div>
            <DeleteAccount
              setOnProcess={(value) => {
                setOnProcess(value);
              }}
              client_info={ClientInfo}
              afterDelete={() => {
                setAfterLogin(false);
              }}
              checked={checked}
              setChecked={() => {
                setChecked(!checked);
              }}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: "0 0 50px 0",
                fontSize: 36,
              }}
            >
              To delete your account, Please login to continue
            </div>
            <div>
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              >
                <GoogleLogin
                  onSuccess={(response) => {
                    setClientInfo(response);
                    setAfterLogin(true);
                  }}
                  onFailure={(error) => {

                  }}
                  ux_mode={"popup"}
                />
              </GoogleOAuthProvider>
            </div>
          </div>
        )}
      </div>
      {onPrecess && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            background: "black",
            opacity: 0.7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#FFFFFF",
              fontSize: 64,
            }}
          >
            Deleting Account ......
          </div>
        </div>
      )}
    </div>
  );
}

export default Delete;
